@charset "utf-8";

/**
* Хлебные крошки
*/
ul.breadcrumbs {
	margin-top: 0;
	margin-left: 0;
	margin-bottom: c(air);
	padding-left: 0;

	> li {
		display: inline-block;
		position: relative;
		margin-bottom: c(air) * .5;
		padding-right: c(air);

		// Разделитель крошек
		&:after {
			content: "/";
			position: absolute;
			right: .25em;
			opacity: .5;
		}

		// После последней крошки нет разделителя
		&:last-child {
			padding-right: 0;

			&:after {
				content: "";
				display: none;
			}
		}
	}
}