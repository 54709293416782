@charset "UTF-8";

.sort-search {
	@include container($flex-wrap: nowrap);
	@include max-screen(c(md)) {
		@include container($flex-wrap: wrap);
	}

	margin-bottom: c(air);

	> div:first-child {
		@include max-screen(c(md)) {
			flex-basis: 100%;
			margin-bottom: c(air);
			margin-right: 0;
		}

		flex-basis: 65.5%;
		background-color: c(price-bg);
		padding: c(control-padding-v) c(control-padding-h);
		margin-right: 3.5rem;

		span,
		a {
			display: inline-block;
			margin-right: 1rem;
			font-weight: 600;
		}
	}

	> div:last-child {
		@include max-screen(c(md)) {
			flex-basis: 100%;
		}

		flex-basis: 30%;

		form {
			@include container($flex-wrap: nowrap);

			> *:first-child {
				flex-basis: 85%;
				border-right: none;
				@include radius(c(control-r), (tl,bl));
			}

			> *:last-child {
				flex-basis: 15%;
				box-shadow: 1px 1px 0 c(good-border) inset, -1px -1px 0 c(good-border) inset;
				@include radius(0);
				@include radius(c(control-r), (tr,br));
			}
		}
	}
}

.settings-filter {
	margin: 0;
	margin-top: 1rem;
	list-style: none;
	padding: 0;

	> li {
		position: relative;
		margin-left: 0;

		.toggle {
			display: block;
			font-weight: bold;
			cursor: pointer;

			span:first-child {
				&:after {
					font-family: "fontello";
					content: "\e802";
					position: absolute;
					left: 1rem;
					top: 0.5rem;
					color: c(color-white);
					@include padding(c(air) / 5, (t,b));
					@include padding(c(air) / 3, (l,r));
					background-color: c(primary-bg);
				}
			}

			&.active {
				span:first-child {
					&:after {
						font-family: "fontello";
						content: "\e803";
						position: absolute;
						left: 1rem;
						top: 0.5rem;
						color: c(color-white);
						@include padding(c(air) / 5, (t,b));
						@include padding(c(air) / 3, (l,r));
						background-color: c(primary-bg);
					}
				}
			}

			span:last-child {
				@include padding(c(air) / 2, (t,b));
				padding-left: c(air) * 2;
				display: block;
				color: c(color-black);
			}
		}

		.slider-range,
		ul {
			display: none;
		}

		ul {
			list-style: none;

			li {
				@include margin(0.5rem, (t,b));
				font-weight: 400;
				margin-left: -2rem;
			}
		}
	}
}