@charset "UTF-8";


.inv {
	display: none;
}

.exit {
	position: absolute !important;
	top: 2rem;
	right: 2rem;
	color: c(primary-link);
	font-size: 2em;
	cursor: pointer;
	z-index: 10000;
	&:hover {
		color: lighten(c(primary-link), 5%);
	}
}