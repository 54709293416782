@charset "UTF-8";


.catalog {
	@include container();

	> a {
		position: relative;
		cursor: pointer;
		color: black;
		text-align: center;
		background-color: white;
		margin: 0;
		border: 1px solid c(good-border);
		&:hover {
			box-shadow: c(good-shadow) 5px 5px 0;
		}
		.sale {
			position: absolute;
			top: -1rem;
			right: -1rem;
			content: 'Распродажа';
			color: white;
			font-size: 0.9em;
			font-weight: bold;
			text-transform: uppercase;
			background-color: c(action);
			padding: (c(air) / 2) (c(air) / 1.5);
		}


		@include min-screen(c(md) + 1) {
			@include grid(3, 3.5rem);
		}

		@include screen(c(sm) + 1, c(md)) {
			@include grid(2, 3.5rem);
		}

		@include max-screen(c(sm)) {
			@include landscape() {
				@include grid(2, 3.5rem);
			}
			@include portrait() {
				@include grid(1, 3.5rem);
			}

		}

		img {
			max-width: 100%;
			max-height: 20rem;
		}

		.price {
			margin-top: 10px;
			font-size: 1.4em;
			font-weight: bold;
			text-transform: uppercase;
			background-color: c(price-bg);
			span {
				display: inline-block;
				font-size: 1em;
				font-weight: bold;
			}
			.old-price {
				color: c(price-color);
				text-decoration: line-through;
				margin-right: c(air);
				span {
					text-decoration: line-through;
				}
			}
			.new-price {
				color: black;
			}
		}

		> div {
			@include padding(c(air), (b));
			> div {
				@include padding(c(air), (l,r));
				h3 {
					font-size: 1.4em;
					margin-top: c(air) / 2;
					margin-bottom: 0;
				}
				p {
					margin-top: 0;
					margin-bottom: c(air) / 2;
				}
			}
		}
	}
}