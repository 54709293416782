@charset "UTF-8";

.thumbs {
	background: linear-gradient(gainsboro, white);
	flex-grow: 10;
	> .column {
		text-align: center;
		> div {
			@include container();
			a {
				img {
					max-width: 100%;
				}

				@include min-screen(c(sm) + 1) {
					@include landscape() {
						@include grid(4, 8rem);
					}
					@include portrait(){
						@include grid(2);
					}
				}

				@include screen(c(xs) + 1, c(sm)) {
					@include landscape() {
						@include grid(4, 6rem);
					}
					@include portrait(){
						@include grid(2);
					}
				}
				@include max-screen(c(xs)) {
					@include landscape() {
						@include grid(4, 4rem);
					}
					@include portrait(){
						@include grid(2);
					}
				}
			}
		}
	}

}