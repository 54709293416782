@charset "utf-8";

/**
* Построчная установка скругления углов
*/
@mixin radius($value, $sides: (tl, tr, bl, br))
{
	$names: (tl: top-left, tr: top-right, bl: bottom-left, br: bottom-right);


	@each $side in $sides
	{
		border-#{map-get($names, $side)}-radius: $value;
	}
}