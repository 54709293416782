@charset "UTF-8";

.contacts-inv {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	z-index: 10000;
	display: none;
	background-color: transparentize(c(color-white), 0.2);
	padding-top: c(air) * 3;

	> section {
		position: relative;
		z-index: 100000;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include max-screen(c(md)) {
			display: block;
			@include padding(c(air) * 2, (t,b));
		}
		.column {
			background: linear-gradient(gainsboro, white);
			max-width: c(lg);
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: nowrap;
			@include max-screen(c(md)) {
				display: block;
				> div {
					margin-bottom: c(air);
				}
			}
			> div:last-child {
				width: 100%;
			}
		}
	}
}