@charset "UTF-8";

.triggers {
	background: linear-gradient(gainsboro, white);
	flex-grow: 10;
	> .column {
		text-align: center;
		> div {
			@include container();
			figure {
				margin: 0;
				img {
					max-width: 100%;
				}

				@include min-screen(c(sm) + 1) {
					@include landscape() {
						@include grid(4);
					}
					@include portrait(){
						@include grid(3);
					}
				}

				@include screen(c(xs) + 1, c(sm)) {
					@include landscape() {
						@include grid(4);
					}
					@include portrait(){
						@include grid(3);
					}
				}
				@include max-screen(c(xs)) {
					@include landscape() {
						@include grid(2);
					}
					@include portrait(){
						@include grid(1, 0);
					}
				}
			}
		}
	}
}