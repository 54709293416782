@charset "utf-8";

/**
* Базовый файл конфигурации
*
* Из всех конфигурационных файлов (тех, что лежат в папке config), настройки собираются в мап конфигурации.
* Впоследствии их значения могут быть получены из мапа как напрямую, так и с помощью вспомогательной функции.
*/

// Мап конфигурации
$__c: ();

// Вспомогательная функция для получения парметров из мапа конфигурации
@function c($param)
{
	@return map-get($__c, $param);
}