@charset "UTF-8";

.slider-range {
	margin-left: 1.5rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	input {
		border: none;
		margin-bottom: 0.5rem;
		font-weight: 300;
	}
	.ui-slider {
		height: 1rem;
		box-shadow: 1px 1px 0 c(primary-link) inset, -1px -1px 0 c(primary-link) inset;
		position: relative;


		/* Ползунок */
		.ui-slider-handle {
			position: absolute;
			z-index: 2;
			width: 13px;   /* Задаем нужную ширину */
			height: 13px;  /* и высоту */
			background: c(secondary-link);
			@include radius(5rem)
			cursor: pointer
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 13px;
				height: 13px;
				background-color: black;
			}
		}
		.ui-slider-range {
			position: absolute;
			z-index: 1;
			font-size: .7em;
			display: block;
			border: 0;
			overflow: hidden;
		}
	}


	/* позиционируем ползунки */
	.ui-slider-horizontal {
		.ui-slider-handle {
			top: -2px;
			margin-left: -6px;
		}
		.ui-slider-range {
			top: 0;
			height: 100%;
		}
		.ui-slider-range-min {
			left: 0;
		}
		.ui-slider-range-max {
			right: 0;
		}
	}
	/* оформление активного участка (между двумя ползунками) */
	.ui-widget-header {
		background: c(primary-link);
		height: 1rem;
	}
}




