@charset "UTF-8";

.sidebar {
	margin-top: -.5rem;
	background-color: #eee;

	.column {
		> ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		@include min-screen(c(xs) + 1) {
			padding: {
				top: 1rem;
				bottom: .5rem;
			};

			> ul {
				display: flex;

				> li {
					margin: {
						right: 2rem;
						bottom: .5rem;
					};
				}
			}
		}

		@include max-screen(c(xs)) {
			padding: 0;

			> ul {
				display: none;

				> li {
					> * {
						display: block;
						padding: 1rem 2rem;
					}
				}
			}
		}
	}
}

.menu-expander {
	display: none;
	padding: 1rem 2rem;
	text-align: left;
	background-color: c(primary-link);
	border: none;
	font-weight: bold;

	@include max-screen(c(xs)) {
		display: block;
	}

	&.active {
		.aside-menu {
			display: block !important;
		}
	}
}