@charset "UTF-8";

.detailed-description {
	width: 100%;
	@include container($align-items: stretch, $align-content: stretch);

	> div {
		@include min-screen(c(sm)+ 1) {
			@include grid(2);
		}

		@include max-screen(c(sm)) {
			@include grid();
		}
	}

	.popup-gallery {
		@include container();

		a {
			&:first-child {
				text-align: center;
				flex-basis: 100%;
				margin-bottom: c(air);
			}

			&:nth-child(n + 2) {
				@include grid(3, 0);

				img {
					max-height: 10rem;
				}
			}
		}
	}
	.characteristic {
		margin-bottom: c(air);
		overflow: hidden;
		position: relative;
		background-color: c(price-bg);
		padding: c(air);
		> div:first-child {
			@include container();
			.price, .sale {
				@include min-screen(c(sm)+ 1) {
					@include grid(2);
				}
				@include max-screen(c(sm)) {
					@include grid();
				}
			}
			.sale {
				position: relative;
				text-align: center;
				color: white;
				font-size: 0.9em;
				font-weight: bold;
				text-transform: uppercase;
				background-color: c(action);
				padding: (c(air) / 2) (c(air) / 1.5);
			}
			.price {
				text-align: center;
				display: inline-block;
				padding: (c(air) / 5) c(air);
				box-shadow: c(good-shadow) 5px 5px 0;
				background-color: c(primary-link);
				font-size: 1.4em;
				font-weight: bold;
				text-transform: uppercase;
				span {
					display: inline-block;
					font-size: 1em;
					font-weight: bold;
				}
				.old-price {
					color: c(price-color);
					text-decoration: line-through;
					margin-right: c(air);
					span {
						text-decoration: line-through;
					}
				}
				.new-price {
					color: black;
				}
			}
		}
		> .text-description {
			margin-top: c(air);
			margin-bottom: c(air);
			h3 {
				margin-top: 0;
			}
		}
		> .description {
			@include container();
			> div {
				width: 100%;
				margin-top: 1rem;
				margin-bottom: 0.5rem;
				font-weight: bold;
			}
			> span {
				margin-bottom: 1rem !important;
				@include min-screen(c(sm) + 1) {
					@include grid(2, 0);
				}
				@include max-screen(c(sm)) {
					@include landscape() {
						@include grid(2, 0);
					}
					@include portrait() {
						@include grid();
					}
				}
				&.key {
					position: relative;
					font-weight: 600;
					> span {
						position: relative;
						z-index: 10;
						background: c(price-bg);
					}
					&:before {
						content: '.....................................................';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
					}
				}
				&.value {
					font-weight: 300;
					position: relative;
					z-index: 20;
					background: c(price-bg);
				}
			}
		}
	}
}