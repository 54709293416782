@charset "UTF-8";

.aside-block {
	border: 1px solid c(primary-border);
	margin-bottom: c(air);
	> div {
		@include max-screen(c(sm)) {
			display: none;
		}
	}

	.menu-expander {
		display: block;
		@include radius(0);
		background-color: c(secondary-link);
		text-align: left;
		color: c(color-white);
		&:hover {
			color: c(primary-link);
		}
	}

	.group-buttons {
		text-align: center;
		margin-top: c(air);
		margin-bottom: c(air);
	}

}