@charset "utf-8";

/**
* Конфигурация текста
*/
$__c: map-merge($__c, (

	// Подключаемые шрифты
		included-fonts: ('roboto'),

	// Основной шрифт
		font-family: ('Roboto', sans-serif),

	// Базовый размер шрифта
		font-size: 1.6rem,

	// Базовая высота строки
		line-height: 1.5,

));