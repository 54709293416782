@charset "utf-8";

/**
* Конфигурация геометрии
*/
$__c: map-merge($__c, (

	// Переменные точек останова
		xs: 480px,
		sm: 762px,
		md: 992px,
		lg: 1200px,

	// Коэффициент воздуха
		air: 2rem,

	// Поля элементов управления (кнопок, полей ввода, выпадающих списки)
		control-padding-v: .5rem,
		control-padding-h: 1rem,

	// Скругление углов у кнопок, полей ввода и выпадающих списков
		control-r: .3rem

));