@charset "UTF-8";

/* Зазаёт флекс параметры для родительского блока */
@mixin container($display: flex, $flex-direction: row, $justify-content: flex-start, $align-items: flex-start, $flex-wrap: wrap, $align-content: flex-start) {
	display: $display;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
	flex-wrap: $flex-wrap;
	align-content: $align-content;
}


/*  Задаем параметры для дочерних элементов
*   $col - количество колонок
*   $gutter - воздух между колонками
*/
@mixin grid($col: 1, $gutter: c(air)) {
	@include margin($gutter, (r,b));

	@if $gutter == 0
	{

		width: calc(100% / #{$col});
	}
	@else
	{
		$gutters: ((($col - 1) * $gutter) / $col);

		width: calc(100% / #{$col} - #{$gutters});

		&:nth-child(#{$col}n) {
			margin-right: 0;

		}
	}

}