@charset "UTF-8";

.footer {
	background: c(primary-bg);
	border-top: 5px solid c(primary-link);
	> .column {
		@include padding(4rem, (t,b));
		color: c(primary-link);
		a {
			color: c(primary-link);
			&:hover {
				color: white;
			}
		}
		@include min-screen(c(xs) + 1) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			> *:last-child {
				text-align: right;
			}
		}
		@include max-screen(c(xs)) {
			display: block;
			> *:first-child {
				margin-bottom: c(air);
			}
		}
	}
}