@charset "UTF-8";

.notice {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10000;
	display: none;
	background-color: transparentize(c(color-white), 0.2);
	> div {
		position: relative;
		z-index: 100000;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.result {
			margin-bottom: c(air);
		}
	}

}