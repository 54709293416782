@charset "utf-8";

/**
* Инициализация настроек макета
*/

// Подключение шрифтов
@if (c(included-fonts))
{
	@each $font-name in c(included-fonts)
	{
		@include font-include($font-name);
	}
}

// Суммарные ширина и высота всех элементов включает собственные величины полей и границ
* {
	box-sizing: border-box;
	font: c(font-size) c(font-family);
	outline: none;
}

// Корневой элемент (документ)
html {
	// Высота корневого элемента приравнивается к высоте окна браузера
	height: 100%;

	// Инициализация значения 1rem
	font-size: (10px / 16px * 100%);

	line-height: normal;
}

// Настройка страницы и контейнеров
body {
	position: relative;
	min-height: 100%;
	font: c(font-size) c(font-family);
	color: c(primary-text);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	background-color: white;

}

.column {
	margin: auto;
	max-width: c(lg);
	padding: c(air);
}

img {
	vertical-align: top;
}

.menu-expander {
	width: 100%;
	padding-top: c(air) * .7 !important;
	padding-bottom: c(air) * .7 !important;

	@include min-screen(c(sm) + 1) {
		display: none;
	}
	@include max-screen(c(sm)) {
		display: block;
		border-radius: 0 !important;
	}
}