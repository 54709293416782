@charset "UTF-8";

.header {
	background: linear-gradient(gainsboro, white);
	> .column {
		max-width: c(lg);
		background-color: #3e5e85;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		img {
			max-height: 4rem;
		}
	}
}