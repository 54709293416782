@charset "utf-8";

/**
* Построчная установка отступов
*/
@mixin margin($value, $sides: (t, r, b, l))
{
	$names: (t: top, r: right, b: bottom, l: left);


	@each $side in $sides
	{
		margin-#{map-get($names, $side)}: $value;
	}
}