@charset "UTF-8";

.label {
	display: inline-block;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-decoration: none !important;
	//background-color: #777;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em;
	margin-right: 5px;
	&:last-child {
		margin-right: 0;
	}
}