@charset "utf-8";

/**
* Настройки текста
*/

// Нижний отступ у блочных текстовых элементов
$type-margin-bottom: c(air);

// Заголовки
h1 {
	font-size: 3em;
	margin-bottom: $type-margin-bottom;
}
h2 {
	font-size: 2.5em;
	margin-bottom: ($type-margin-bottom * 0.75);
}
h3 {
	font-size: 2em;
	margin-bottom: ($type-margin-bottom * 0.5);
}
h4 {
	font-size: 1.7em;
	margin-bottom: ($type-margin-bottom * 0.5);
}
h5 {
	font-size: 1.5em;
	margin-bottom: ($type-margin-bottom * 0.5);
}
h6 {
	font-size: 1.2em;
	margin-bottom: ($type-margin-bottom * 0.5);
}
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: (c(line-height) * 0.75);
}

// Параграфы
p {
	line-height: c(line-height);
	margin-bottom: ($type-margin-bottom * 0.5);

	&:last-child {
		margin-bottom: 0;
	}
}
p + h2 {
	margin-top: ($type-margin-bottom * 2);
}
p + h3,
p + h4,
p + h5,
p + h6 {
	margin-top: ($type-margin-bottom * 1.5);
}

// Выделение
strong,
b {
	font-weight: bolder;
}
em,
i {
	font-style: italic;
}

// Индексы
sup {
	vertical-align: super;
	font-size: smaller;
}
sub {
	vertical-align: sub;
	font-size: smaller;
}

.typography {
	h1 {
		width: 100%;
		padding-bottom: 0.5rem;
		border-bottom: 5px solid c(primary-bg);
		@include max-screen(c(xs)) {
			font-size: 1.5em;
		}
	}
	img {
		max-width: 100%;
	}
	ul,
	ol {
		margin-left: c(air);
		line-height: c(line-height) * 1.2;
		ul, ol {
			margin-left: c(air);
			margin-bottom: c(air) * 0.5;
			font-size: 0.9em;
		}
		ul {
			list-style-type: circle;
		}
		ol {
			list-style-type: lower-alpha;
		}
		margin-bottom: c(air) * 1.2;
	}

	ul {
		list-style-type: disc;
	}
	ol {
		list-style-type: decimal;
	}

	table {
		width: 100%;
		margin-bottom: c(air);

		@include max-screen(c(sm)) {
			font-size: 0.8em;
		}

		@include max-screen(c(md)) {
			display: block;
			overflow-x: scroll;
		}

		caption {
			font-style: italic;
			margin-bottom: c(air) / 4;
		}

		thead {
			background-color: c(primary-border);
		}
		td, th {
			@include padding(c(air) / 2, (t,b));
			@include padding(c(air), (l,r));
			border: 1px solid c(primary-border);
		}
		th {
			font-weight: bold;
		}
		tbody tr:nth-child(even) {
			background-color: #eee;
		}
	}

	blockquote {
		@include padding(c(air) / 2);
		background-color: c(primary-bg);
		display: inline-block;
		margin-bottom: c(type-margin-bottom);
	}
}