@charset "UTF-8";

//.article-aside {
	//background: linear-gradient(gainsboro, white);
	//flex-grow: 10;

	//> .column {
	//	display: flex;
	//	flex-direction: row;
	//	justify-content: space-between;
	//	align-items: flex-start;
	//	flex-wrap: wrap;
	//	align-content: flex-start;
	//
	//	> ul.breadcrumbs {
	//		align-self: stretch;
	//		flex-basis: 100%;
	//	}
	//
	//	> aside {
	//		flex-basis: 23rem;
	//		margin-right: c(air);
	//		@include max-screen(c(sm)) {
	//			flex-basis: 100%;
	//			margin-right: 0;
	//		}
	//	}
	//	> main {
	//		flex-basis: calc(100% - (23rem + 2rem));
	//		@include max-screen(c(sm)) {
	//			flex-basis: 100%;
	//		}
	//	}
	//}
//}