@charset "UTF-8";

.header-text {
	background: linear-gradient(gainsboro, white);
	> .column {
		max-width: c(lg);

		@include min-screen(c(sm) + 1) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			> * {
				display: inline-block;
				&:nth-child(2) {
					@include margin(c(air), (l,r));
					flex-grow: 1;
				}
			}
		}

		@include max-screen(c(sm)) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;

			> * {
				display: inline-block;
				&:nth-child(2) {
					@include margin(c(air), (t,b));
					align-self: stretch;
				}
				&:last-child {
					align-self: stretch;
				}
			}
		}

		img {
			max-height: 4rem;
		}
	}
}