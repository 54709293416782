@charset "UTF-8";


@charset "UTF-8";


.menu {
	margin: 0;
	padding: 0;
	list-style-type: none;
	@include max-screen(c(sm)) {
		&.menu {
			display: none;
		}
	}
	&.main-menu {
		@include min-screen(c(sm) + 1) {
			li {
				display: inline-block;
				margin-right: c(air);
				margin-bottom: (c(air) * .25);
				text-transform: uppercase;
				font-weight: bold;
				font-size: 1.1em;

				&:last-child {
					margin-right: 0;
				}

				&.selected > span {
					font: inherit;
					padding: c(air) * .5;
					background: c(primary-link);
					color: c(primary-bg);
				}

				> a {
					font-size: 1.1em;
					color: c(primary-link);
					&:hover {
						color: c(color-white);
					}
				}

				> * {
					display: block;
				}
			}
		}

		@include max-screen(c(sm)) {
			display: none;
			background-color: #eee;

			> li {
				> * {
					display: block;
					padding: 1rem 2rem;
				}
			}
		}
	}

	&.aside-menu {
		li {
			display: block;
			margin: 0;
			padding: 1rem !important;
			border-bottom: 1px solid c(primary-border);
			&:last-child {
				border-bottom: none;
			}

			&.selected > span {
				//background: c(color-black);
				text-decoration: none !important;
				color: c(secondary-link);
				background: none;
				padding: 0;
			}

			&.selected > span,
			a {
				padding: 0;
				font-weight: 400;
				//text-transform: capitalize;
				text-decoration: underline;
				font-size: 1em !important;
				display: inline;
				//padding: (c(air) * .5) c(air);
			}

			> a {
				color: black;
				&:hover {
					text-decoration: none !important;
					background: none;
					color: c(secondary-link);
				}
			}
		}

		//@include min-screen(c(sm) + 1) {
		//	li {
		//		display: block;
		//		margin: 0;
		//
		//		&.selected > span {
		//			background: c(color-black);
		//			color: c(color-white);
		//		}
		//
		//		&.selected > span,
		//		a {
		//			display: block;
		//			padding: (c(air) * .5) c(air);
		//		}
		//
		//		> a {
		//			text-decoration: none;
		//			&:hover {
		//				background: c(primary-link);
		//				color: c(color-white);
		//			}
		//		}
		//	}
		//}
	}
}
