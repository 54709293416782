@charset "UTF-8";

.search {
	display: flex;
	margin-bottom: c(air);

	> *:first-child {
		flex-grow: 1;
		margin-right: .5rem;
	}
}