@charset "UTF-8";

.callback {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	z-index: 10000;
	display: none;
	background-color: transparentize(c(secondary-link), 0.5);
	padding-top: c(air) * 3;

	> section {
		position: relative;
		z-index: 100000;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include max-screen(c(md)) {
			display: block;
			@include padding(c(air) * 2, (t,b));
		}
		.column {
			background: white;
			max-width: c(lg);
			form {
				@include container($flex-direction: column, $align-items: stretch);
				h5 {
					margin-top: 0;
				}
				> * {
					display: block;
					margin-bottom: c(air);

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}