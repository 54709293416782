@charset "UTF-8";



.mfp-gallery {
	@include container;

	> a {
		height: 12rem;
		overflow: hidden;
		position: relative;

		> img {
			max-width: 100%;
			position: absolute;
			bottom: 0;
		}
	}


	@include min-screen(c(md) + 1) {
		> a {
			@include grid(4);
			margin-bottom: c(air);

			&:nth-last-child(-n + 4) {
				margin-bottom: 0;
			}
		}
	}


	@include max-screen(c(md)) {
		> a {
			@include grid(2);

			&:nth-last-child(-n + 2) {
				margin-bottom: 0;
			}
		}
	}
}