@charset "utf-8";

/**
* Ссылки
*/
a {
	color: c(secondary-link);

	&:hover {
		color: lighten(c(secondary-link), 10%);
	}
}