@charset "utf-8";

/**
* Поля ввода
*/
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
textarea {
	padding: (c(control-padding-v) - .1rem) (c(control-padding-h) - .1rem);
	border: 1px solid c(primary-input-border);
}