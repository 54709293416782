@charset "utf-8";

/**
* Пагинатор
*/
.pagination {
	margin-bottom: c(air);
	//text-align: center;
	padding-left: 0;

	&:last-child {
		margin-bottom: 0;
	}

	> li {
		display: inline-block;
		> * {
			display: inline-block;
			padding: (c(air) * 0.25) (c(air) * 0.5);
			@include margin(c(air) * 0.1, (r, l));
			@include margin(c(air) * 0.5, (b));
		}
		> a {
			text-decoration: none;
			color: c(color-black);
			background-color: c(primary-button-bg);
			border: 1px solid c(good-border);

			&:hover {
				background-color: c(secondary-link);
				color: c(color-white);
			}
		}
		> .active {
			background-color: c(color-white);
			color: c(color-black);
			border: 1px solid c(good-border);
		}

		//> a,
		//> .active {
		//	border-radius: c(control-r);
		//}
	}
}