@charset "UTF-8";

.header-links {
	background: c(primary-bg);
	border-bottom: 5px solid c(primary-link);
	> .column {
		max-width: c(lg);

		@include min-screen(c(sm) + 1) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			> * {
				display: inline-block;
				&:nth-child(2) {
					@include margin(c(air), (l,r));
					flex-grow: 1;
					//align-self: flex-end;
				}
			}
		}

		@include max-screen(c(sm)) {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;

			> * {
				display: inline-block;
				&:nth-child(2) {
					@include margin(c(air) * -1, (l,r));
					@include margin(c(air), (t,b));
					align-self: stretch;
				}
				&:last-child {
					align-self: center;

				}
			}
		}

		img {
			@include radius(25rem);
			@include padding(0.5rem, (t,b));
			box-shadow: transparentize(black, 0.6) 1px 1px 10px;
		}
	}
}