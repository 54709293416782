@charset "UTF-8";

@import "./resources/assets/sass/utils/mq-new";

.mfp-wrap {
	-webkit-overflow-scrolling: touch;
}

/**
 * Fade-zoom animation for first dialog
 */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;

	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;



	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;

	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);

	opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}



/**
 * Fade-move animation for second dialog
 */

/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;

	-webkit-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );

}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-moz-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-ms-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	-o-transform: translateY(0) perspective( 600px ) rotateX( 0 );
	transform: translateY(0) perspective( 600px ) rotateX( 0 );
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
	opacity: 0;

	-webkit-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
	opacity: 0;

	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
	opacity: 0;
}


.mfp-content {
	#menu {
		position: relative;
		padding-top: 48rem;
		max-width: 240rem;
		margin: auto;

		.nav {
			width: 100%;

			.menu {
				margin: 0;
				padding-left: 0;

				&__item {
					list-style-type: none;
					padding: 0;
					margin-bottom: 16rem;
				}

				&__span {
					font-weight: bold;
					text-transform: uppercase;
					color: $primary;
				}

				&__link {
					text-decoration: none;
					font-weight: bold;
					text-transform: uppercase;
					color: $w;

					&:hover {
						color: $primary;
					}
				}
			}
		}
		.ghost {
			border-color: $w;
			color: $w;

			&:hover {
				border-color: lighten($w, 20%);
				color: lighten($w, 20%);
			}

			&:focus {
				box-shadow: transparentize($w, 0.75) 0 0 0 2rem;
			}

			@include mobile {
				width: 100%;
			}
		}
	}
}

.mfp-close {
	font-size: 3.6rem;
	height: 3.2rem;
	width: 32rem;
	opacity: 1;

	&:hover {
		opacity: $mfp-controls-opacity;
	}
}

.modal {
	padding-top: 32rem;
	padding-bottom: 32rem;
	position: relative;
	max-width: 768rem;
	margin: auto;

	h2, h3 {
		margin-top: 0;
	}

	&__content {
		padding: 48rem;
		background-color: $w;
		border-radius: 8rem;

		@include phone {
			padding: 24rem 16rem;
		}
	}
}

