@charset "UTF-8";

.tabs {
	ul {
		background: none;
		margin: 0;
		margin-left: 2rem;
		padding: 0;
		li {
			padding: 0;
			display: inline-block;
			//padding: c(air);
			@include padding(2rem, (l,r));
			a {
				color: black;
				text-decoration: none;
			}
		}
	}
	.ui-state-default {
		background-color: darken(c(price-bg), 20%);
		@include padding(2rem, (l,r));
		@include padding(1rem, (t,b));
	}
	.ui-tabs-active {
		background-color: c(price-bg);
		@include padding(2rem, (l,r));
		@include padding(1rem, (t,b));
	}
	> div {
		padding: c(air);
		background-color: c(price-bg);
		h2 {
			font-size: 1.4em;
			margin-top: 0;
		}
	}

}