@charset "utf-8";

/**
* Построчная установка границ
*/
@mixin border($value, $sides: (t, r, b, l))
{
	$names: (t: top, r: right, b: bottom, l: left);


	@each $side in $sides
	{
		border-#{map-get($names, $side)}: $value;
	}
}