@charset "UTF-8";

.banners {
	width: 100%;
	overflow: hidden;

	&__item {
		margin-bottom: 20px;
	}
	&__img {
		width: 100%;
	}
}
