@charset "UTF-8";

.content {
	background-color: white;
	flex-grow: 10;

	.column {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		align-content: flex-start;

		> ul.breadcrumbs {
			align-self: stretch;
			flex-basis: 100%;
		}

		> aside {
			flex-basis: 25rem;
			margin-right: c(air);
			@include max-screen(c(sm)) {
				flex-basis: 100%;
				margin-right: 0;
				margin-bottom: c(air);
			}
		}

		main {
			flex-basis: 100%;
			overflow: hidden;
		}

		aside + main {
			flex-basis: calc(100% - (25rem + 2rem));
			@include max-screen(c(sm)) {
				flex-basis: 100%;
			}
		}
	}
}
