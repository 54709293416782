@charset "utf-8";

/**
* Цвета
*/
$__c: map-merge($__c, (

	// основные цвета
	primary-bg: #4c579e,
	primary-text: black,
	primary-border: #e2e2e2,

	primary-link: #f9d844,
	primary-button-bg: #f9d844,
	primary-input-border: #c5c5c5,

	// Второстепенные цвета
	secondary-link: #4c579e,
	secondary-button-bg: #4c579e,
	secondary-input-border: #f00,

	// Переменные по цветам
	color-black: black,
	color-white: white,
	action: #ff0d00,
	good-shadow: #a5abce,
	good-border: #c5c5c5,
	price-color: #ff0000,
	price-bg: #eeeeee,

));