@charset "UTF-8";

@import "base/normolize";

// Конфигурация
@import "config/base";
@import "config/colors";
@import "config/layout";
@import "config/type";

// Вспомогательные конструкции
@import "utils/helpers/helpers";
@import "utils/mixins/border";
@import "utils/mixins/font-include";
@import "utils/mixins/grids";
@import "utils/mixins/margin";
@import "utils/mixins/media-queries";
@import "utils/mixins/padding";
@import "utils/mixins/radius";

// Базовое оформление
@import "base/base";
@import "base/normolize";
@import "base/type";

/** Подключаем разметку блоков **/

@import "layout/callback/callback";
@import "layout/components/accordion";
@import "layout/components/aside-block";
@import "layout/components/breadcrumbs";
@import "layout/components/buttons";
@import "layout/components/content";
@import "layout/components/detailed-description";
@import "layout/components/filter";
@import "layout/components/fontello";
@import "layout/components/inputs";
@import "layout/components/label";
@import "layout/components/links";
@import "layout/components/menu";
@import "layout/components/mfp-gallery";
@import "layout/components/pagination";
@import "layout/components/sdelai-banyu";
@import "layout/components/search";
@import "layout/components/selects";
@import "layout/components/slider-range";
@import "layout/components/tabs";
@import "layout/components/slick";
@import "layout/contacts/contacts-inv";
@import "layout/content/article-aside";
@import "layout/content/catalog-aside";
@import "layout/cover/cover";
@import "layout/footer/footer";
@import "layout/header/header";
@import "layout/header/header-links";
@import "layout/header/header-text";
@import "layout/nav/sidebar";
@import "layout/pop-up/notice";
@import "layout/thumbs/thumbs";
@import "layout/triggers/triggers";
@import "layout/banners/banners";

$primary: c(primary-bg);
$h: c(primary-text);
$w: c(color-white);
@import "node_modules/magnific-popup/src/css/settings";
$mfp-overlay-color: $h;
$mfp-overlay-opacity: 0.9;
$mfp-inner-close-icon-color: $w;
@import "node_modules/magnific-popup/src/css/main";
@import "layout/pop-up/modals";
