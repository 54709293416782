@charset "utf-8";

/**
* Кнопки
*/
button,
.button {
	cursor: pointer;
}

.button {
	white-space: nowrap;
	padding: c(control-padding-v) c(control-padding-h);
	@include radius(c(control-r));
	border: none;
	background: c(primary-button-bg);
	color: black;

	&:hover {
		color: white;
		background: c(secondary-button-bg);
	}
	&.link {
		border: 3px solid c(primary-link);
		//text-decoration: underline;
		@include padding(1rem, (t,b));
		position: relative;
		background: none;
		color: c(primary-link);
		padding-right: c(air) * 3;
		font-weight: bold;
		font-size: 1.1em;
		border-radius: 0;

		&:hover {
			color: white;
			border: 3px solid white;
		}
		&:before {
			position: absolute;
			content: '';
			width: 35px;
			height: 27px;
			background-image: url("../img/layout/roulette.png");
			top: 20%;
			right: 1rem;
		}
	}
	&.white {
		background-color: c(color-white);
		text-decoration: none;
		text-transform: uppercase;
		color: c(primary-bg);
		&:hover {
			color: c(primary-link);
		}
	}
}