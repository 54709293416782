@charset "UTF-8";

.accordion {
	@include margin(1rem, (l,r,b));
	h3 {
		position: relative;
		font-size: 1em;
		width: 100%;
		font-weight: bold;
		margin-left: 0.5rem;
		cursor: pointer;
	}
	h3 + div {
		padding-left: 2rem;
		input {
			margin-right: 0.5rem;
		}
	}
}

.icon-down, .icon-up {
	position: relative;
	left: -0.5rem;
	color: white;
	background-color: c(secondary-link);
}